.p-component, .p-component * {
    box-sizing: border-box;
}
.p-hidden {
    display: none;
}
.p-hidden-accessible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.p-hidden-accessible input,
.p-hidden-accessible select {
    transform: scale(0);
}
.p-reset {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    text-decoration: none;
    font-size: 100%;
    list-style: none;
}
.p-disabled {
    cursor: default !important;
}
.p-disabled a {
    cursor: default !important;
}
.p-component-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.p-overflow-hidden {
    overflow: hidden;
}
.p-unselectable-text {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.p-scrollbar-measure {
    width: 100px;
    height: 100px;
    overflow: scroll;
    position: absolute;
    top: -9999px;
}
.p-clearfix:after {
    content: "";
    display: table;
    clear: both;
}

@-webkit-keyframes p-fadein {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}
@keyframes p-fadein {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

input[type="button"],
input[type="submit"],
input[type="reset"],
input[type="file"]::-webkit-file-upload-button,
button {
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
}

/* Overlay Animations */
.p-input-overlay {
    -webkit-transform: translateY(5%);
    -ms-transform: translateY(5%);
    transform: translateY(5%);
    opacity: 0;
    -webkit-transition: transform .3s, opacity .3s;
    transition: transform .3s, opacity .3s;
}

.p-input-overlay-visible {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}

.p-input-overlay-hidden {
    opacity: 0;
    -webkit-transform: translateY(5%);
    -ms-transform: translateY(5%);
    transform: translateY(5%);
    -webkit-transition: transform .3s, opacity .15s;
    transition: transform .3s, opacity .15s;
}

.p-menu-overlay {
    -webkit-transform: translateY(5%);
    -ms-transform: translateY(5%);
    transform: translateY(5%);
    opacity: 0;
    -webkit-transition: transform .3s, opacity .3s;
    transition: transform .3s, opacity .3s;
}

.p-menu-overlay-visible {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}

.p-menu-overlay-hidden {
    opacity: 0;
    -webkit-transform: translateY(5%);
    -ms-transform: translateY(5%);
    transform: translateY(5%);
    -webkit-transition: transform .3s, opacity .15s;
    transition: transform .3s, opacity .15s;
}

.p-link {
    text-align: left;
    background-color: transparent;
    background: transparent;
	margin: 0;
	padding: 0;
	border: none;
	cursor: pointer;
}

/* Toggleable content with slide animation */
.p-toggleable-content-collapsed {
    display: none;
}

.p-toggleable-content-enter {
    max-height: 0;
    display: block;
}

.p-toggleable-content-enter-active {
    max-height: 1000px;
    overflow: hidden;
    transition: max-height cubic-bezier(0.86, 0, 0.07, 1) 400ms;
}

.p-toggleable-content-enter-done {
    display: block;
}

.p-toggleable-content-exit {
    max-height: 1000px;
    display: block;
}

.p-toggleable-content-exit-active {
    max-height: 0;
    display: block;
    overflow: hidden;
    transition: max-height cubic-bezier(0.86, 0, 0.07, 1) 250ms;
}

.p-toggleable-content-exit-done {
    display: none;
}

/* Floating Label */
.p-float-label {
    display: block;
    position:relative;
}

.p-float-label > label {
  font-weight:normal;
  position:absolute;
  pointer-events:none;
  left: .25em;
  top: 50%;
  margin-top: -.5em;
  transition: 0.3s ease all;
  -moz-transition: 0.3s ease all;
  -webkit-transition: 0.3s ease all;
  color: #898989;
  line-height: 1;
}

.p-sr-only {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    word-wrap: normal !important;
}
